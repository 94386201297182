<template>
  <template v-if="loading"> Loading...</template>
  <template v-else-if="!namespace">
    <error-display v-if="error" headerMessage="Error" :error="error" />
    <warning-display
      v-else
      headerMessage="Warning"
      warning="Namespace Not Found"
    />
  </template>
  <template v-else-if="namespace">
    <h2 class="page-title">
      {{ namespace.name }}
    </h2>
    <div
      class="flex flex-row mb-2 -mt-2 lg:-mt-6 min-h-8"
      ref="namespaceDescription"
    >
      <p
        v-if="!editNamespaceDescription && namespace.description?.trim()"
        class="mt-1 text-gray-700"
      >
        {{ namespace.description }}
      </p>
      <p
        v-else-if="!editNamespaceDescription && !namespace.description?.trim()"
        class="mt-1 text-gray-700 font-bold"
      >
        Add description
      </p>
      <input
        v-else
        class="w-full"
        type="text"
        v-model="textareaContent"
        @keyup.enter="handleNamespaceDescriptionEdit"
      />
      <button
        @click="editNamespaceDescription = true"
        class="text-gray-700 w-6 mx-2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          fill="currentColor"
          data-slot="icon"
          class="hi-micro hi-pencil-square inline-block size-4"
        >
          <path
            d="M13.488 2.513a1.75 1.75 0 0 0-2.475 0L6.75 6.774a2.75 2.75 0 0 0-.596.892l-.848 2.047a.75.75 0 0 0 .98.98l2.047-.848a2.75 2.75 0 0 0 .892-.596l4.261-4.262a1.75 1.75 0 0 0 0-2.474Z"
          />
          <path
            d="M4.75 3.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h6.5c.69 0 1.25-.56 1.25-1.25V9A.75.75 0 0 1 14 9v2.25A2.75 2.75 0 0 1 11.25 14h-6.5A2.75 2.75 0 0 1 2 11.25v-6.5A2.75 2.75 0 0 1 4.75 2H7a.75.75 0 0 1 0 1.5H4.75Z"
          />
        </svg>
      </button>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-3 gap-4 lg:gap-8">
      <Card
        :totalCount="namespace.publications.totalCount"
        :cardTitle="'publications'"
        :enableLink="true"
        @router-link="
          $router.push({
            name: 'publications',
            query: { namespace: namespace?.id },
          })
        "
      />
      <Card
        :totalCount="namespace.licenses.totalCount"
        :cardTitle="'licenses'"
        :enableLink="true"
        @router-link="
          $router.push({
            name: 'licenses',
            query: { namespace: namespace?.id },
          })
        "
      />
      <Card
        :totalCount="namespace.files.totalCount"
        :cardTitle="'files'"
        :enableLink="false"
      >
        {{ fileSize }}
      </Card>
      <Card
        :totalCount="namespace?.pastMonthLoans"
        :cardTitle="`total loans for ${pastMonthStart.format('MMMM')}`"
        :enableLink="false"
      />
      <Card
        :totalCount="namespace?.currentMonthLoans"
        :cardTitle="`total loans for ${currentMonthStart.format('MMMM')}`"
        :enableLink="false"
      />
    </div>
    <h3 class="text-lg font-bold mt-4 mb-2">Related namespaces</h3>
    <hr class="mb-4" />
    <div class="grid grid-cols-1 md:grid-cols-3 gap-4 lg:gap-8">
      <Card
        :totalCount="namespace.allowedContentProviders.totalCount"
        cardTitle="content providers"
        :enableLink="true"
        @router-link="
          $router.push({
            name: 'related-namespaces',
            query: { id: namespace?.id },
          })
        "
      />
      <Card
        :totalCount="namespace.allowedLicensers.totalCount"
        cardTitle="licensers"
        :enableLink="true"
        @router-link="
          $router.push({
            name: 'related-namespaces',
            query: { id: namespace?.id },
          })
        "
      />
    </div>
  </template>
</template>

<script setup lang="ts">
import { useMutation, useQuery } from '@vue/apollo-composable';
import { computed } from '@vue/reactivity';
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';
import { onClickOutside } from '@vueuse/core';
import ErrorDisplay from '../ErrorDisplay.vue';
import WarningDisplay from '../WarningDisplay.vue';

import { graphql } from '../../__generated__/gql';

import Card from '@/components/base/Card.vue';
import { readableFileSize } from '../../utils';
import dayjs from 'dayjs';

const route = useRoute();
const namespaceId = ref(route.params.id as string);

const toast = useToast();

const namespaceDescription = ref(null);

onClickOutside(namespaceDescription, () => {
  editNamespaceDescription.value = false;
});

watch(
  () => route.params.id,
  (newId) => (namespaceId.value = newId as string)
);

/* Loan amounts */
const now = dayjs();
const currentMonthStart = now.startOf('month');
const currentMonthEnd = now.endOf('month');
const pastMonthStart = currentMonthStart.subtract(1, 'month');
const pastMonthEnd = pastMonthStart.endOf('month');

const { result, loading, refetch, error } = useQuery(
  graphql(`
    query NamespaceInventory(
      $namespaceId: ID!
      $currentMonthStart: DateTime!
      $currentMonthEnd: DateTime!
      $pastMonthStart: DateTime!
      $pastMonthEnd: DateTime!
    ) {
      namespace(id: $namespaceId) {
        id
        name
        description

        publications {
          totalCount
        }

        licenses {
          totalCount
        }

        files {
          totalCount
          totalSize
        }

        allowedContentProviders {
          totalCount
        }

        allowedLicensers {
          totalCount
        }

        currentMonthLoans: loansAmount(
          filterBy: {
            createdAfter: $currentMonthStart
            createdBefore: $currentMonthEnd
          }
        )

        pastMonthLoans: loansAmount(
          filterBy: {
            createdAfter: $pastMonthStart
            createdBefore: $pastMonthEnd
          }
        )
      }
    }
  `),
  () => ({
    namespaceId: namespaceId.value,
    currentMonthStart: currentMonthStart.toISOString(),
    currentMonthEnd: currentMonthEnd.toISOString(),
    pastMonthStart: pastMonthStart.toISOString(),
    pastMonthEnd: pastMonthEnd.toISOString(),
  })
);

const fileSize = computed(() => {
  const totalSize = result.value?.namespace?.files.totalSize;
  return totalSize ? readableFileSize(totalSize) : '';
});

const namespace = computed(() => result.value?.namespace ?? null);

/* Description */
const editNamespaceDescription = ref(false);
const textareaContent = ref('');
watch(namespace, () => {
  textareaContent.value = namespace.value?.description ?? '';
});

const {
  mutate: updateNamespaceDescription,
  onDone: afterUpdate,
  onError: onUpdateFailed,
} = useMutation(
  graphql(`
    mutation UpdateNamespace($input: NamespaceUpdateInput!) {
      updateNamespace(input: $input) {
        id
      }
    }
  `)
);
afterUpdate(() => refetch());
onUpdateFailed(() => toast.error('Namespace update has failed.'));

const handleNamespaceDescriptionEdit = () => {
  const description =
    textareaContent.value.trim() === '' ? null : textareaContent.value.trim();

  editNamespaceDescription.value = false;
  updateNamespaceDescription({
    input: {
      id: namespaceId.value,
      description,
    },
  });
};
</script>
