<template>
  <message-confirmation
    :isHidden
    :bodyInfoMessage
    titleInfoMessage="Trigger Repackaging"
    buttonConfirmMessage="Yes, trigger repackaging"
    :showCancelButton="true"
    @answerUser="answerUser($event, protectionTypeToTrigger)"
    variant="warning"
    :large="true"
  />
  <error-display
    :error="triggerRepackagingError"
    headerMessage="Failed to trigger the repackaging."
  />

  <div class="space-y-6 p-2 mb-2 rounded-md bg-gray-100 dark:text-gray-100">
    <namespace-selector
      class="mb-2"
      v-model="namespaceSelectedRef"
      resource-type="files"
      inject-option-all-namespaces
    />
    <file-nature-selector
      v-model="selectedNatures"
      :fileNatureOptions="['CONTENT', 'COVER']"
    />
    <representation-nature-selector v-model="selectedRepresentationNatures" />

    <dm-button class="m-1" @click="onFilterClick()" variant="default" size="s"
      >FILTER</dm-button
    >

    <div
      class="items-center rounded bg-indigo-200 px-2 py-2 text-sm font-semibold leading-4 text-indigo-700 inline float-right"
    >
      <span>Total Count: {{ totalCount }}</span>
    </div>
  </div>

  <div class="flex-wrap lg:flex">
    <dm-button
      class="m-1"
      @click="onTriggerClick(null)"
      variant="default"
      size="s"
      >REPACK ALL</dm-button
    >
    <div v-for="button in repackType">
      <dm-button
        class="m-1"
        @click="onTriggerClick(button.value)"
        variant="default"
        size="s"
        >REPACK MISSING {{ button.label }}</dm-button
      >
    </div>
  </div>

  <Table
    :headers
    :hasNextPage="filesPageInfo?.hasNextPage"
    :hasPreviousPage="filesPageInfo?.hasPreviousPage"
    @changePage="changePage"
    @selectAll="selectAllFiles"
    :isInputHeaderChecked="isCheckAll"
  >
    <tr v-if="loading">
      <td>Loading...</td>
    </tr>
    <tr v-else-if="error">
      <td>{{ error }}</td>
    </tr>
    <tr
      v-else-if="filesWithNoRepresentations"
      v-for="file of filesWithNoRepresentations"
      :key="file.node.id"
      class="even:bg-gray-50 dark:even:bg-gray-900/50"
    >
      <td class="p-3 text-gray-500 dark:text-gray-400">
        <router-link :to="{ name: 'file', params: { id: file.node.id } }">
          <b>
            {{ shortId(file.node.id) }}
          </b>
        </router-link>
      </td>
      <td class="p-3 text-gray-500 dark:text-gray-400">
        <dm-date :date="file.node.created" />
      </td>

      <td class="text-center">
        <file-trigger-action
          :file="file.node"
          buttonSize="xs"
          :fileList="null"
          :disableAction="false"
        />
      </td>

      <td class="p-3 text-gray-500 text-left box-content">
        <span
          class="font-semibold inline-flex px-3 py-1 leading-4 items-center space-x-1 text-sm rounded"
        >
          <span>
            {{ file.node.mime }}
          </span>
        </span>
      </td>
      <td class="p-3 text-left">
        <input
          type="checkbox"
          :checked="
            selectedFiles?.some((fileId) => fileId.fileId === file.node.id)
          "
          :id="`${file.node.id}`"
          class="size-4 rounded border border-gray-400 text-blue-500 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 dark:border-gray-600 dark:bg-gray-800 dark:ring-offset-gray-900 dark:checked:border-transparent dark:checked:bg-blue-500 dark:focus:border-blue-500"
          @click="addSelectedFileToList(file.node)"
        />
      </td>
    </tr>
  </Table>
</template>

<script setup lang="ts">
import Table from '@/components/base/Table.vue';
import MessageConfirmation from '@/components/base/DialogMessage.vue';
import RepresentationNatureSelector from '@/components/files/RepresentationNatureSelector.vue';
import FileNatureSelector from '@/components/files/FileNatureSelector.vue';
import NamespaceSelector from '@/components/namespaces/NamespaceSelector.vue';
import ErrorDisplay from '../ErrorDisplay.vue';
import FileTriggerAction from './FileTriggerAction.vue';
import { shortId } from '../../utils';
import { useToast } from 'vue-toastification';
import { computed, ref, Ref } from 'vue';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { graphql } from '../../__generated__/gql';
import {
  FileFilter,
  File,
  GetFilesWithNoRepresentationsQueryVariables,
} from '../../__generated__/graphql';

const headers = [
  { label: 'File ID' },
  { label: 'Created At' },
  { label: 'Packaging status' },
  { label: 'Mime type' },
  { label: '', type: 'input_checkbox' },
];

// VARIABLES
const perPage = 25;

const namespaceSelectedRef: Ref<{ id: string; name: string } | null> =
  ref(null);
const namespaceId = computed(
  () => namespaceSelectedRef.value && namespaceSelectedRef.value.id
);

const selectedNatures = ref(['CONTENT']);
const selectedNaturesTemp = ref(['CONTENT']);
const selectedRepresentationNatures = ref(['ACS', 'LCP']);
const selectedRepresentationNaturesTemp = ref(['ACS', 'LCP']);
const variables: Ref<GetFilesWithNoRepresentationsQueryVariables> = computed(
  () => ({
    ...paginationVariables.value,
    ...filterVariables.value,
  })
);

const paginationVariables = computed(() => ({
  first: perPage,
  last: null,
  before: undefined,
  after: undefined,
}));

const filterVariables = computed(
  () =>
    ({
      filterFileBy: {
        natures: selectedNaturesTemp.value,
        representationNatures: selectedRepresentationNaturesTemp.value,
        namespaceId: namespaceId.value,
      },
    } as FileFilter)
);

const onFilterClick = () => {
  if (
    selectedNatures.value.length === 0 ||
    selectedRepresentationNatures.value.length === 0
  ) {
    popErrorToast(
      'Error',
      'Select at least one representation and one nature type.'
    );
    return;
  }

  selectedNaturesTemp.value = selectedNatures.value;
  selectedRepresentationNaturesTemp.value = selectedRepresentationNatures.value;

  if (isCheckAll.value) {
    isCheckAll.value = false;
  }
};

const repackType = [
  { label: 'ACS', value: 'AcsRepresentation' },
  { label: 'LCP', value: 'LcpRepresentation' },
  { label: 'CANTOOK_AUDIO', value: 'CantookAudioRepresentation' },
  { label: 'CLOUDFLARE_IMAGE', value: 'CloudflareImageRepresentation' },
];
const toast = useToast();

const popErrorToast = (toastTitle: string, toastContent: string) => {
  toast.error(toastTitle.toUpperCase() + '\n' + toastContent);
};

const popToast = (toastTitle: string, toastContent: string) => {
  toast(toastTitle.toUpperCase() + '\n' + toastContent);
};

//SELECTION
const isCheckAll = ref(false);
const selectedFiles = ref<{ fileId: string; protectionType: string[] }[]>([]);

const selectAllFiles = () => {
  if (selectedFiles.value.length === filesWithNoRepresentations.value.length) {
    isCheckAll.value = false;
    selectedFiles.value = [];
  } else {
    isCheckAll.value = true;
    selectedFiles.value = filesWithNoRepresentations.value.map((file) => ({
      fileId: file.node.id,
      protectionType: getProtectionTypeDependingOnFileMimeType(file.node.mime),
    }));
  }
};

const addSelectedFileToList = (file: Pick<File, 'id' | 'mime'>) => {
  if (!selectedFiles.value?.some((fileId) => fileId.fileId === file.id)) {
    selectedFiles.value.push({
      fileId: file.id,
      protectionType: getProtectionTypeDependingOnFileMimeType(file.mime),
    });
  } else {
    selectedFiles.value = selectedFiles.value.filter((fileId) => {
      return fileId.fileId !== file.id;
    });
  }
};

const resetAllFields = () => {
  isHidden.value = true;
  isCheckAll.value = false;
  bodyInfoMessage.value = '';
  fileIdsToRepack.value = [];
  protectionTypeToTrigger.value = '';
  selectedFiles.value = [];
};

const { result, loading, error, fetchMore } = useQuery(
  graphql(`
    query getFilesWithNoRepresentations(
      $first: Int
      $last: Int
      $before: String
      $after: String
      $filterFileBy: FileFilter
    ) {
      files(
        filterBy: $filterFileBy
        orderBy: { field: CREATED_AT, direction: DESC }
        first: $first
        last: $last
        before: $before
        after: $after
      ) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            id
            mime
            nature
            created
            representations {
              __typename
            }
          }
        }
      }
    }
  `),
  variables
);

const filesPageInfo = computed(() => result.value?.files?.pageInfo ?? null);

const changePage = (e: 'next' | 'prev') => {
  if (e == 'next') {
    fetchMore({
      variables: {
        first: perPage,
        last: null,
        after: filesPageInfo.value?.endCursor,
      },
    });
  } else {
    fetchMore({
      variables: {
        first: null,
        last: perPage,
        before: filesPageInfo.value?.startCursor,
      },
    });
  }
  if (isCheckAll.value) {
    isCheckAll.value = false;
  }
};

const filesWithNoRepresentations = computed(() => {
  return result.value?.files.edges.map((edge) => edge) ?? [];
});

const totalCount = computed(() => result.value?.files.totalCount ?? 0);

const getProtectionTypeDependingOnFileMimeType = (fileMime: string) => {
  if (fileMime === 'application/epub+zip' || fileMime === 'application/pdf') {
    return ['AcsRepresentation', 'LcpRepresentation'];
  } else if (fileMime === 'application/zip') {
    return ['CantookAudioRepresentation', 'LcpRepresentation'];
  } else if (fileMime === 'image/jpeg' || fileMime === 'image/png') {
    return ['CloudflareImageRepresentation'];
  } else {
    return [];
  }
};

const {
  mutate: triggerAcsRepackagingByFileIds,
  onDone: onTriggerAcsRepackagingByFileIdsDone,
  onError: onTriggerAcsRepackagingByFileIdsError,
  error: triggerAcsRepackagingByFileIdsError,
} = useMutation(
  graphql(`
    mutation triggerAcsRepackagingByFileIds($fileIds: [ID!]!) {
      triggerAcsRepackagingByFileIds(fileIds: $fileIds) {
        fileIds
      }
    }
  `)
);

onTriggerAcsRepackagingByFileIdsError((error) => {
  resetAllFields();
  popErrorToast('ACS Packaging Error', error.message);
});

onTriggerAcsRepackagingByFileIdsDone(() => {
  popToast('ACS packaging', 'will be applied within the next minute.');
});

const {
  mutate: triggerLcpRepackagingByFileIds,
  onDone: onTriggerLcpRepackagingByFileIdsDone,
  onError: onTriggerLcpRepackagingByFileIdsError,
  error: triggerLcpRepackagingByFileIdsError,
} = useMutation(
  graphql(`
    mutation triggerLcpRepackagingByFileIds($fileIds: [ID!]!) {
      triggerLcpRepackagingByFileIds(fileIds: $fileIds) {
        fileIds
      }
    }
  `)
);

onTriggerLcpRepackagingByFileIdsError((error) => {
  resetAllFields();
  popErrorToast('LCP Packaging Error', error.message);
});

onTriggerLcpRepackagingByFileIdsDone(() => {
  popToast('LCP packaging', 'will be applied within the next minute.');
});

const {
  mutate: triggerCloudflareImageRepackagingByFileIds,
  onDone: onTriggerCloudflareImageRepackagingByFileIdsDone,
  onError: onTriggerCloudflareImageRepackagingByFileIdsError,
  error: triggerCloudflareImageRepackagingByFileIdsError,
} = useMutation(
  graphql(`
    mutation triggerCloudflareImageRepackagingByFileIds($fileIds: [ID!]!) {
      triggerCloudflareImageRepackagingByFileIds(fileIds: $fileIds) {
        fileIds
      }
    }
  `)
);

onTriggerCloudflareImageRepackagingByFileIdsError((error) => {
  resetAllFields();
  popErrorToast('Cloudflare Image Packaging Error', error.message);
});

onTriggerCloudflareImageRepackagingByFileIdsDone(() => {
  popToast(
    'Cloudflare Image  packaging',
    'will be applied within the next minute.'
  );
});

const {
  mutate: triggerCantookAudioRepackagingByFileIds,
  onDone: onTriggerCantookAudioRepackagingByFileIdsDone,
  onError: onTriggerCantookAudioRepackagingByFileIdsError,
  error: triggerCantookAudioRepackagingByFileIdsError,
} = useMutation(
  graphql(`
    mutation triggerCantookAudioRepackagingByFileIds($fileIds: [ID!]!) {
      triggerCantookAudioRepackagingByFileIds(fileIds: $fileIds) {
        fileIds
      }
    }
  `)
);

onTriggerCantookAudioRepackagingByFileIdsError((error) => {
  resetAllFields();
  popErrorToast('Cantook Audio Packaging Error', error.message);
});

onTriggerCantookAudioRepackagingByFileIdsDone(() => {
  popToast(
    'Cantook Audio packaging',
    'will be applied within the next minute.'
  );
});

const triggerRepackagingError = computed(() => {
  resetAllFields();
  return (
    triggerAcsRepackagingByFileIdsError.value ||
    triggerLcpRepackagingByFileIdsError.value ||
    triggerCloudflareImageRepackagingByFileIdsError.value ||
    triggerCantookAudioRepackagingByFileIdsError.value
  );
});

//confirm mutation
const isHidden = ref(true);
const bodyInfoMessage = ref('');
const protectionTypeToTrigger = ref('');
const fileIdsToRepack: Ref<
  null | { fileId: string; protectionType: string[] }[]
> = ref([]);

const checkForExistingRepresentation = (
  fileId: string,
  protectionType: string
) => {
  return filesWithNoRepresentations.value?.some(
    (file) =>
      file.node.id === fileId &&
      (file.node.representations.length === 0 ||
        !file.node.representations.some(
          (representation) => representation.__typename === protectionType
        ))
  );
};

const onTriggerClick = (protectionType: string | null) => {
  if (selectedFiles.value.length === 0) {
    popErrorToast('Error', 'Select files before trigger a repackaging.');
    return;
  }

  if (protectionType) {
    fileIdsToRepack.value = selectedFiles.value?.filter(
      (file) =>
        file.protectionType.includes(protectionType) &&
        checkForExistingRepresentation(file.fileId, protectionType)
    );

    protectionTypeToTrigger.value = protectionType;
  } else {
    fileIdsToRepack.value = selectedFiles.value;
    protectionTypeToTrigger.value = 'all';
  }

  if (fileIdsToRepack.value.length === 0) {
    popErrorToast(
      'Error',
      'The selected files does not match the selected packaging type.'
    );
    return;
  }

  bodyInfoMessage.value = `Are you sure you want to trigger ${
    protectionType ?? 'each missing'
  } repackaging for ${fileIdsToRepack.value?.length} files?`;

  isHidden.value = false;
};

const answerUser = async (answer: boolean, protectionType: string) => {
  if (answer) {
    if (!fileIdsToRepack.value || fileIdsToRepack.value?.length === 0) {
      popErrorToast('Error', "Can't trigger repackaging without files.");
      resetAllFields();
      return;
    }
    switch (protectionType) {
      case 'AcsRepresentation':
        await triggerAcsRepackagingByFileIds({
          fileIds: fileIdsToRepack.value?.map((file) => file.fileId),
        });
        break;
      case 'LcpRepresentation':
        await triggerLcpRepackagingByFileIds({
          fileIds: fileIdsToRepack.value?.map((file) => file.fileId),
        });
        break;
      case 'CloudflareImageRepresentation':
        await triggerCloudflareImageRepackagingByFileIds({
          fileIds: fileIdsToRepack.value?.map((file) => file.fileId),
        });
        break;
      case 'CantookAudioRepresentation':
        await triggerCantookAudioRepackagingByFileIds({
          fileIds: fileIdsToRepack.value?.map((file) => file.fileId),
        });
        break;
      case 'all':
        await repackAllFilesPackages();
    }
  }
  resetAllFields();
};

const repackAllFilesPackages = async () => {
  const acsFileList = fileIdsToRepack.value
    ?.filter(
      (file) =>
        file.protectionType.includes('AcsRepresentation') &&
        checkForExistingRepresentation(file.fileId, 'AcsRepresentation')
    )
    .map((file) => file.fileId);

  const lcpFileList = fileIdsToRepack.value
    ?.filter(
      (file) =>
        file.protectionType.includes('LcpRepresentation') &&
        checkForExistingRepresentation(file.fileId, 'LcpRepresentation')
    )
    .map((file) => file.fileId);

  const coverFileList = fileIdsToRepack.value
    ?.filter(
      (file) =>
        file.protectionType.includes('CloudflareImageRepresentation') &&
        checkForExistingRepresentation(
          file.fileId,
          'CloudflareImageRepresentation'
        )
    )
    .map((file) => file.fileId);

  const audioFileList = fileIdsToRepack.value
    ?.filter(
      (file) =>
        file.protectionType.includes('CantookAudioRepresentation') &&
        checkForExistingRepresentation(
          file.fileId,
          'CantookAudioRepresentation'
        )
    )
    .map((file) => file.fileId);

  if (acsFileList && acsFileList.length > 0) {
    await triggerAcsRepackagingByFileIds({
      fileIds: acsFileList,
    });
  }

  if (lcpFileList && lcpFileList.length) {
    await triggerLcpRepackagingByFileIds({
      fileIds: lcpFileList,
    });
  }

  if (audioFileList && audioFileList.length > 0) {
    await triggerCantookAudioRepackagingByFileIds({
      fileIds: audioFileList,
    });
  }

  if (coverFileList && coverFileList.length > 0) {
    await triggerCloudflareImageRepackagingByFileIds({
      fileIds: coverFileList,
    });
  }
};
</script>
<style>
.table-header {
  @apply bg-gray-100 px-3 py-4 text-left tracking-wider font-semibold text-sm uppercase text-gray-700 dark:bg-gray-700/25 dark:text-gray-50;
}
</style>
