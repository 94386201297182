<template>
  <div class="space-y-8 mb-6">
    <form
      class="space-y-6"
      v-if="result && result.publication && result.publication.metadata"
    >
      <FormSection description="This is the publication you are modifying.">
        <div>
          {{ result.publication.isbn }}
          <span v-if="result.publication.namespace?.name">
            ({{ result.publication.namespace.name }})
          </span>
        </div>
      </FormSection>

      <FormSection
        description="Miscellaneous metadata fields associated to the publication."
      >
        <FormTextInput
          :initial-value="result.publication.metadata.title"
          label="Title"
          v-model="titleRef"
          :onDiffers="(value) => (titleHasChanged = value)"
        ></FormTextInput>

        <FormTextInput
          :initial-value="result.publication.metadata.subtitle"
          label="Subtitle"
          v-model="subtitleRef"
          :onDiffers="(value) => (subtitleHasChanged = value)"
          placeholder="Enter a subtitle or leave empty."
        ></FormTextInput>

        <FormSelectInput
          :initial-value="result.publication.metadata.nature"
          label="Nature"
          v-model="natureRef"
          :onDiffers="(value) => (natureHasChanged = value)"
          :options="['EPUB_BOOK', 'PDF_BOOK', 'AUDIO_BOOK']"
        ></FormSelectInput>

        <FormTextInput
          :initial-value="result.publication.metadata.lang"
          label="Language"
          v-model="langRef"
          :onDiffers="(value) => (langHasChanged = value)"
          :onErrors="(errors) => (langErrors = errors)"
          :validation="
            (value) =>
              value && value.length > 5
                ? [
                    'Language must be shorter than 5 characters. Use shortcodes like `fra`, `spa` or `eng`.',
                  ]
                : []
          "
          placeholder="Enter the language."
        ></FormTextInput>

        <error-display
          v-if="langErrors"
          :error="langErrors"
          headerMessage="Invalid language value"
        />

        <FormTextAreaInput
          :initial-value="result.publication.metadata.description"
          label="Description"
          v-model="descriptionRef"
          :onDiffers="(value) => (descriptionHasChanged = value)"
          placeholder="Enter the description or leave empty."
          :formInfo="descriptionFormInfo"
        ></FormTextAreaInput>

        <FormTextAreaInput
          :initial-value="result.publication.metadata.summary"
          label="Summary"
          v-model="summaryRef"
          :onDiffers="(value) => (summaryHasChanged = value)"
          placeholder="Enter the summary or leave empty."
          :formInfo="summaryFormInfo"
        ></FormTextAreaInput>

        <FormTextInput
          :initial-value="result.publication.metadata.imprint"
          label="Imprint"
          v-model="imprintRef"
          :onDiffers="(value) => (imprintHasChanged = value)"
          placeholder="Enter the imprint or leave empty."
        ></FormTextInput>

        <FormNumberInput
          :initial-value="result.publication.metadata.pageCount"
          label="Number of Pages"
          v-model="pageCountRef"
          :onDiffers="(value) => (pageCountHasChanged = value)"
          placeholder="Enter the imprint or leave empty."
        ></FormNumberInput>

        <FormTextInput
          :initial-value="result.publication.metadata.paperIsbn"
          label="Paper ISBN"
          v-model="paperIsbnRef"
          :onDiffers="(value) => (paperIsbnHasChanged = value)"
          placeholder="Enter the paper ISBN or leave empty."
        ></FormTextInput>

        <FormTextInput
          :initial-value="result.publication.metadata.publisher"
          label="Publisher"
          v-model="publisherRef"
          :onDiffers="(value) => (publisherHasChanged = value)"
          placeholder="Enter the publisher."
        ></FormTextInput>

        <div>
          <label for="pusblished" class="italic font-medium"
            >Publication Date</label
          >
          <FormDateInput
            :initial-value="datePartOfOriginalPublished"
            v-model="publishedRef"
            :onDiffers="(value) => (publishedHasChanged = value)"
            placeholder="Enter the publication date or leave empty (YYYY-MM-DD)."
          ></FormDateInput>
        </div>
        <FormCheckboxInput
          :initialValue="result.publication.metadata.hasAdultConsideration"
          label="Adults only"
          v-model="hasAdultConsideration"
          :onDiffers="(value) => (hasAdultConsiderationHasChanged = value)"
        ></FormCheckboxInput>
        <FormTypedKeyValuePairsInput
          label="Contributors"
          v-model="contributorsRef"
          :onDiffers="(value) => (contributorsHasChanged = value)"
          :initialValue="
            result.publication.metadata.contributors?.map((contributor) => ({
              label: contributor.role,
              value: contributor.person.name,
            })) ?? []
          "
          :labels="[
            'AUTHOR',
            'TRANSLATOR',
            'EDITOR',
            'ARTIST',
            'ILLUSTRATOR',
            'LETTERER',
            'PENCILER',
            'COLORIST',
            'INKER',
            'NARRATOR',
          ]"
        >
        </FormTypedKeyValuePairsInput>

        <FormTypedKeyValuePairsInput
          label="Subjects"
          v-model="subjectsRef"
          :onDiffers="(value) => (subjectsHasChanged = value)"
          :initialValue="
            result.publication.metadata.subjects.map((subject) => ({
              label: subject.scheme,
              value: subject.code,
            }))
          "
          :labels="['THEMA', 'BISAC', 'BIC', 'FEEDBOOKS', 'CUSTOM']"
        >
        </FormTypedKeyValuePairsInput>

        <FormOpenListInput
          label="Collections"
          v-model="collectionsRef"
          :onDiffers="(value) => (collectionsHasChanged = value)"
          :initialValue="
            result.publication.metadata.collections.map((c) => c.label)
          "
        >
        </FormOpenListInput>
      </FormSection>

      <FormSection
        description="The publication's intended audiences."
        class="space-y-6"
      >
        <FormEnumListInput
          label="Audiences"
          v-model="audiencesRef"
          :onDiffers="(value) => (audiencesHasChanged = value)"
          :initialValue="
            result.publication.metadata.audiences.map((a) => `${a.code}`)
          "
          :options="['ADULT', 'YOUTH', 'TEEN']"
        >
        </FormEnumListInput>

        <FormIntRangesInput
          label="Audience Age Ranges"
          :from="0"
          :to="99"
          v-model="ageRangeAudiencesRef"
          :onDiffers="(value) => (ageRangeAudiencesHasChanged = value)"
          :initialValue="result.publication.metadata.ageRangeAudiences"
          :validation="ageRangeValidation"
        >
        </FormIntRangesInput>
      </FormSection>

      <FormSection
        description="A description of the accessibility properties of this publication."
        class="space-y-6"
      >
        <FormTextAreaInput
          :initial-value="result.publication.metadata.accessibility?.summary"
          label="Accessibility Summary"
          v-model="accessibilitySummaryRef"
          :onDiffers="(value) => (accessibilitySummaryHasChanged = value)"
          placeholder="Enter the accessibility summary or leave empty."
        ></FormTextAreaInput>

        <FormTextAreaInput
          :initial-value="
            result.publication.metadata.accessibility?.certification
              ?.certifiedBy
          "
          label="Accessibility Certified By"
          v-model="accessibilityCertByRef"
          :onDiffers="(value) => (accessibilityCertByHasChanged = value)"
          placeholder="Enter the name of the person or organization or leave empty."
        ></FormTextAreaInput>

        <FormTextAreaInput
          :initial-value="
            result.publication.metadata.accessibility?.certification?.credential
          "
          label="Accessibility Certification Credential"
          v-model="accessibilityCertCredentialRef"
          :onDiffers="
            (value) => (accessibilityCertCredentialHasChanged = value)
          "
          placeholder="Enter the credential or leave empty."
        ></FormTextAreaInput>

        <FormOpenListInput
          label="Accessibility Certification Reports (URLs)"
          v-model="accessibilityCertReportsRef"
          :onDiffers="
            (value) => (accessibilityCertReportsListHasChanged = value)
          "
          :initialValue="
            result.publication.metadata.accessibility?.certification?.reports as string[]
          "
          placeholder="Enter the report URL."
        ></FormOpenListInput>

        <FormOpenListInput
          label="Accessibility Profiles (URLs)"
          v-model="accessibilityConformsToRef"
          :onDiffers="
            (value) => (accessibilityConformsToListHasChanged = value)
          "
          :initialValue="
            result.publication.metadata.accessibility?.conformsTo as string[]
          "
          placeholder="Enter the profile URL."
        >
        </FormOpenListInput>

        <FormEnumListInput
          label="Accessibility Access Modes"
          v-model="accessibilityAccessModesRef"
          :onDiffers="
            (value) => (accessibilityAccessModesListHasChanged = value)
          "
          :initialValue="
            result.publication.metadata.accessibility?.accessModes as string[]
          "
          :options="[
            'TEXTUAL',
            'VISUAL',
            'AUDITORY',
            'CHART_ON_VISUAL',
            'CHEM_ON_VISUAL',
            'COLOR_DEPENDENT',
            'DIAGRAM_ON_VISUAL',
            'MATH_ON_VISUAL',
            'MUSIC_ON_VISUAL',
            'TACTILE',
            'TEXT_ON_VISUAL',
          ]"
        >
        </FormEnumListInput>

        <FormEnumTupleListInput
          label="Accessibility Access Modes Sufficients"
          v-model="accessibilityAccessModesSufficientsRef"
          :onDiffers="
            (value) =>
              (accessibilityAccessModesSufficientsListHasChanged = value)
          "
          :initialValue="
            result.publication.metadata.accessibility?.accessModesSufficient as string[][]
          "
          :options="[['VISUAL'], ['AUDITORY'], ['VISUAL', 'AUDITORY']]"
        >
        </FormEnumTupleListInput>

        <FormEnumListInput
          label="Supported Accessibility Features"
          v-model="accessibilityFeaturesRef"
          :onDiffers="(value) => (accessibilityFeaturesListHasChanged = value)"
          :initialValue="
            result.publication.metadata.accessibility?.features as string[]
          "
          :options="[
            'ANNOTATION',
            'ARIA',
            'INDEX',
            'PRINT_PAGE_NUMBERS',
            'READING_ORDER',
            'STRUCTURAL_NAVIGATION',
            'TABLE_OF_CONTENTS',
            'TAGGED_PDF',
            'ALTERNATIVE_TEXT',
            'AUDIO_DESCRIPTION',
            'CAPTIONS',
            'DESCRIBED_MATHS',
            'LONG_DESCRIPTION',
            'RUBY_ANNOTATIONS',
            'SIGN_LANGUAGE',
            'TRANSCRIPT',
            'DISPLAY_TRANSFORMABILITY',
            'SYNCHRONIZED_AUDIO_TEXT',
            'TIMINGS_CONTROL',
            'UNLOCKED',
            'CHEMML',
            'LATEX',
            'MATHML',
            'TTS_MARKUP',
            'HIGH_CONTRAST_AUDIO',
            'HIGH_CONTRAST_DISPLAY',
            'LARGE_PRINT',
            'BRAILLE',
            'TACTILE_GRAPHIC',
            'TACTILE_OBJECT',
            'NONE',
          ]"
        ></FormEnumListInput>

        <FormEnumListInput
          label="Accessibility Hazards"
          v-model="accessibilityHazardsRef"
          :onDiffers="(value) => (accessibilityHazardsListHasChanged = value)"
          :initialValue="
            result.publication.metadata.accessibility?.hazards as string[]
          "
          :options="[
            'FLASHING',
            'NO_FLASHING_HAZARD',
            'MOTION_SIMULATION',
            'NO_MOTION_SIMULATION_HAZARD',
            'SOUND',
            'NO_SOUND_HAZARD',
            'UNKNOWN',
            'NONE',
          ]"
        ></FormEnumListInput>
      </FormSection>

      <FormSection
        description="If publication is part of a series, fill the following fields."
      >
        <div class="p-2 lg:p-2 float-right">
          <select
            v-model="isPartOfTypeRef"
            class="float-right block border border-gray-200 rounded px-3 py-2 leading-6 w-44"
          >
            <option value="periodical">Periodical</option>
            <option value="bookseries">Book Series</option>
          </select>
        </div>
        <br />
        <br />

        <FormTextInput
          :initial-value="result.publication.metadata.isPartOf?.issn"
          label="Series ISSN"
          v-model="seriesIssnRef"
          :onDiffers="(value) => (seriesIssnHasChanged = value)"
          placeholder="Enter the series ISSN or leave empty."
        ></FormTextInput>

        <FormSelectInput
          v-if="
            isPartOfTypeRef == 'periodical' &&
            result.publication.metadata.isPartOf?.__typename == 'Periodical'
          "
          :initial-value="result.publication.metadata.isPartOf?.type"
          label="Series Type"
          v-model="seriesTypeRef"
          :onDiffers="(value) => (seriesTypeHasChanged = value)"
          :options="['NEWSPAPER', 'MAGAZINE']"
        ></FormSelectInput>

        <FormTextInput
          :initial-value="result.publication.metadata.isPartOf?.name"
          label="Series Name"
          v-model="seriesNameRef"
          :onDiffers="(value) => (seriesNameHasChanged = value)"
          placeholder="Enter the series name or leave empty."
        ></FormTextInput>

        <FormNumberInput
          v-if="
            isPartOfTypeRef == 'bookseries' &&
            result.publication.metadata.isPartOf?.__typename == 'BookSeries'
          "
          :initial-value="result.publication.metadata.isPartOf?.position"
          label="Position in Series"
          v-model="seriesPositionRef"
          :onDiffers="(value) => (seriesPositionHasChanged = value)"
        ></FormNumberInput>

        <FormTextInput
          v-if="
            isPartOfTypeRef == 'periodical' &&
            result.publication.metadata.isPartOf?.__typename == 'Periodical'
          "
          :initial-value="result.publication.metadata.isPartOf?.issueNumber"
          label="Series Issue Number"
          v-model="seriesIssueNumberRef"
          :onDiffers="(value) => (seriesIssueNumberHasChanged = value)"
          placeholder="Enter the series number or leave empty."
        ></FormTextInput>
      </FormSection>

      <div class="md:flex md:flex-row md:space-x-5" v-if="saveChangesError">
        <div class="md:flex-none md:w-1/3 text-center md:text-left"></div>
        <div class="flex flex-col rounded shadow-sm overflow-hidden md:w-2/3">
          <error-display
            :error="saveChangesError"
            headerMessage="Failed to save changes."
          />
        </div>
      </div>

      <div class="md:flex md:flex-row md:space-x-5">
        <div class="md:flex-none md:w-1/3 text-center md:text-left"></div>
        <div class="flex flex-col rounded md:w-2/3 space-y-6">
          <div>
            <dm-button
              class="mr-4 shadow"
              type="submit"
              @click.prevent="saveChanges()"
              :loading="saveChangesLoading"
              :enabled="changesExist && noInputErrors"
              >Save Changes
              {{
                changesCount > 0 ? `(${changesCount} fields changed)` : ''
              }}</dm-button
            >
            <dm-button
              v-if="result.publication.extractedMetadata"
              class="mr-4 shadow"
              type="submit"
              @click.prevent="handleExtractedMetadata()"
              :enabled="
                mergeExtractedMetadataDone ||
                (!mergingExtractedMetadataChangesNothing &&
                  !mergeExtractedMetadataDone)
              "
              >{{
                mergeExtractedMetadataDone
                  ? 'Revert merge'
                  : 'Merge extracted metadata'
              }}
            </dm-button>
          </div>
          <div>
            <router-link
              :to="{
                name: 'publication',
                query: { entity: publicationId },
              }"
              class="p-3 mt-3 font-medium shadow text-sm bg-gray-50 hover:bg-gray-100 hover:bg-opacity-75 active:bg-gray-50 text-indigo-600 hover:text-indigo-500"
              >Return to publication</router-link
            >
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';
import { Ref, computed, ref } from 'vue';
import { useQuery, useMutation } from '@vue/apollo-composable';
import utc from 'dayjs';
import { isEqual } from 'lodash';
import { graphql } from '../../../__generated__/gql';

import FormDateInput from '../../base/DateInput.vue';
import ErrorDisplay from '../../ErrorDisplay.vue';
import FormSection from './Section.vue';
import FormTextAreaInput from './TextAreaInput.vue';
import FormTextInput from './TextInput.vue';
import FormCheckboxInput from './CheckBoxInput.vue';
import FormNumberInput from './NumberInput.vue';
import FormSelectInput from './SelectInput.vue';
import FormTypedKeyValuePairsInput from './TypedKeyValuePairsInput.vue';
import FormOpenListInput from './OpenListInput.vue';
import FormEnumListInput from './EnumListInput.vue';
import FormEnumTupleListInput from './EnumTupleListInput.vue';
import FormIntRangesInput from './IntRangesInput.vue';
import {
  AccessMode,
  AccessibilityFeature,
  AccessibilityHazard,
  AgeRangeInYears,
  PeriodicalType,
  PublicationMetadata,
  PublicationNature,
  UpdateMetadataMutationVariables,
  Audience,
  Role,
  SubjectScheme,
} from '../../../__generated__/graphql';

const route = useRoute();
const router = useRouter();
const publicationId = computed(() =>
  Array.isArray(route.params.id) ? route.params.id[0] : route.params.id
);

const accessibilityAccessModesRef = ref<AccessMode[] | undefined>();
const accessibilityAccessModesSufficientsRef = ref<AccessMode[][]>();
const accessibilityCertByRef = ref<string>();
const accessibilityCertCredentialRef = ref<string | null>();
const accessibilityCertReportsRef = ref<string[] | undefined>();
const accessibilityConformsToRef = ref<string[] | undefined>();
const accessibilityFeaturesRef = ref<AccessibilityFeature[] | undefined>();
const accessibilityHazardsRef = ref<AccessibilityHazard[] | undefined>();
const accessibilitySummaryRef = ref<string>();
const ageRangeAudiencesRef = ref<AgeRangeInYears[]>([]);
const ageRangeFromRef = ref<number>(0);
const ageRangeToRef = ref<number>(99);
const audiencesRef = ref<string[]>();
const collectionsRef = ref<string[] | undefined>();
const contributorsRef = ref<{ label: string; value: string }[] | undefined>();
const descriptionRef = ref<string | undefined>();
const imprintRef = ref<string | undefined>();
const isPartOfTypeRef = ref<string>('periodical');
const langRef = ref<string>('fra');
const natureRef = ref<PublicationNature>('EPUB_BOOK');
const newCollectionLabelRef = ref<string>('');
const newContributorNameRef = ref<string>('');
const newContributorRoleRef = ref<string>('AUTHOR');
const newSubjectCodeRef = ref<string>('');
const newSubjectSchemeRef = ref<string>('THEMA');
const pageCountRef = ref<number | undefined>();
const paperIsbnRef = ref<string | undefined>();
const publishedRef = ref<string | undefined>();
const publisherRef = ref<string | undefined>();
const seriesIssnRef = ref<string | undefined>();
const seriesIssueNumberRef = ref<string | undefined>();
const seriesNameRef = ref<string | undefined>();
const seriesPositionRef = ref<number | undefined>();
const seriesTypeRef = ref<PeriodicalType | undefined>();
const subjectsRef = ref<
  { label: SubjectScheme; value: string }[] | undefined
>();
const subtitleRef = ref<string | undefined>();
const summaryRef = ref<string | undefined>();
const titleRef = ref<string>('no title');
const hasAdultConsideration = ref<boolean | undefined>(false);

const langErrors = ref<string[]>([]);

const ageRangeValidation = ({
  from,
  to,
}: {
  from: number | '';
  to: number | '';
}): string[] => {
  const errors: string[] = [];
  if (from === '') {
    errors.push('From cannot be empty');
  }
  if (to === '') {
    errors.push('To cannot be empty');
  }
  if (from >= to) {
    errors.push('To must be greater than From');
  }
  if (0 > to || to > 140) {
    errors.push('To must be a valid age');
  }
  if (0 > from || from > 140) {
    errors.push('From must be a valid age');
  }
  return errors;
};

const _PUBLICATION_EDIT_METADATA_FRAGMENT = graphql(`
  fragment PublicationEditMetadata on Publication {
    id
    isbn
    namespace {
      id
      name
    }

    extractedMetadata {
      accessibility {
        conformsTo
        accessModes
        accessModesSufficient
        summary
        features
        hazards
        certification {
          certifiedBy
          reports
          credential
        }
      }
    }

    metadata {
      nature
      title
      subtitle
      pageCount
      lang
      description
      summary
      imprint
      publisher
      published
      paperIsbn
      hasAdultConsideration
      audiences {
        code
      }
      ageRangeAudiences {
        from
        to
      }
      contributors {
        role
        person {
          name
        }
      }
      subjects {
        scheme
        code
      }
      collections {
        label
      }
      accessibility {
        conformsTo
        accessModes
        accessModesSufficient
        summary
        features
        hazards
        certification {
          certifiedBy
          reports
          credential
        }
      }
      isPartOf {
        issn
        name
        ... on Periodical {
          issueNumber
          type
        }
        ... on BookSeries {
          position
        }
      }
    }
  }
`);

const { result } = useQuery(
  graphql(`
    query GetPublicationForEdit($id: ID!) {
      publication(id: $id) {
        ...PublicationEditMetadata
      }
    }
  `),
  () => ({
    id: publicationId.value,
  })
);

const datePartOfOriginalPublished = computed<string | undefined>(
  (): string | undefined => {
    if (result.value?.publication?.metadata?.published) {
      return utc(result.value.publication.metadata.published).format(
        'YYYY-MM-DDTHH:mm'
      );
    }
  }
);

const accessibilityAccessModesListHasChanged = ref(false);
const accessibilityAccessModesSufficientsListHasChanged = ref(false);
const accessibilityCertByHasChanged = ref(false);
const accessibilityCertCredentialHasChanged = ref(false);
const accessibilityCertReportsListHasChanged = ref(false);
const accessibilityConformsToListHasChanged = ref(false);
const accessibilityFeaturesListHasChanged = ref(false);
const accessibilityHazardsListHasChanged = ref(false);
const accessibilitySummaryHasChanged = ref(false);
const ageRangeAudiencesHasChanged = ref(false);
const audiencesHasChanged = ref(false);
const collectionsHasChanged = ref(false);
const contributorsHasChanged = ref(false);
const descriptionHasChanged = ref(false);
const imprintHasChanged = ref(false);
const langHasChanged = ref(false);
const natureHasChanged = ref(false);
const pageCountHasChanged = ref(false);
const paperIsbnHasChanged = ref(false);
const publishedHasChanged = ref(false);
const publisherHasChanged = ref(false);
const seriesIssnHasChanged = ref(false);
const seriesIssueNumberHasChanged = ref(false);
const seriesNameHasChanged = ref(false);
const seriesPositionHasChanged = ref(false);
const seriesTypeHasChanged = ref(false);
const subjectsHasChanged = ref(false);
const subtitleHasChanged = ref(false);
const summaryHasChanged = ref(false);
const titleHasChanged = ref(false);
const hasAdultConsiderationHasChanged = ref<boolean>(false);

const allChanges = [
  accessibilityAccessModesListHasChanged,
  accessibilityAccessModesSufficientsListHasChanged,
  accessibilityCertByHasChanged,
  accessibilityCertCredentialHasChanged,
  accessibilityCertReportsListHasChanged,
  accessibilityConformsToListHasChanged,
  accessibilityFeaturesListHasChanged,
  accessibilityHazardsListHasChanged,
  accessibilitySummaryHasChanged,
  ageRangeAudiencesHasChanged,
  audiencesHasChanged,
  collectionsHasChanged,
  contributorsHasChanged,
  descriptionHasChanged,
  imprintHasChanged,
  langHasChanged,
  natureHasChanged,
  pageCountHasChanged,
  paperIsbnHasChanged,
  publishedHasChanged,
  publisherHasChanged,
  seriesIssnHasChanged,
  seriesIssueNumberHasChanged,
  seriesNameHasChanged,
  seriesPositionHasChanged,
  seriesTypeHasChanged,
  subjectsHasChanged,
  subtitleHasChanged,
  summaryHasChanged,
  titleHasChanged,
  hasAdultConsiderationHasChanged,
];

const changesCount = computed(() => {
  return allChanges.reduce((acc, curr) => {
    return acc + (curr.value ? 1 : 0);
  }, 0);
});

const seriesHasChanged = computed(
  () =>
    seriesPositionHasChanged.value ||
    seriesIssueNumberHasChanged.value ||
    seriesNameHasChanged.value ||
    seriesTypeHasChanged.value ||
    seriesIssnHasChanged.value
);

const accessibilityCertificationHasChanged = computed(
  () =>
    accessibilityCertByHasChanged.value ||
    accessibilityCertReportsListHasChanged.value ||
    accessibilityCertCredentialHasChanged.value
);

const accessibilityHasChanged = computed(
  () =>
    accessibilityConformsToListHasChanged.value ||
    accessibilityAccessModesListHasChanged.value ||
    accessibilitySummaryHasChanged.value ||
    accessibilityFeaturesListHasChanged.value ||
    accessibilityHazardsListHasChanged.value ||
    accessibilityAccessModesSufficientsListHasChanged.value ||
    accessibilityCertificationHasChanged.value
);

const variables: Ref<UpdateMetadataMutationVariables> = computed(() => ({
  id: publicationId.value,
  changes: {
    ...(titleHasChanged.value ? { title: titleRef.value } : {}),
    ...(subtitleHasChanged.value
      ? {
          subtitle: subtitleRef.value == '' ? null : subtitleRef.value,
        }
      : {}),
    ...(pageCountHasChanged.value
      ? {
          pageCount: pageCountRef.value ?? null,
        }
      : {}),
    ...(natureHasChanged.value ? { nature: natureRef.value } : {}),
    ...(langHasChanged.value ? { lang: langRef.value } : {}),
    ...(descriptionHasChanged.value
      ? { description: descriptionRef.value }
      : {}),
    ...(summaryHasChanged.value ? { summary: summaryRef.value } : {}),
    ...(hasAdultConsiderationHasChanged.value
      ? { hasAdultConsideration: hasAdultConsideration.value }
      : {}),
    ...(imprintHasChanged.value ? { imprint: imprintRef.value } : {}),
    ...(paperIsbnHasChanged.value ? { paperIsbn: paperIsbnRef.value } : {}),
    ...(publisherHasChanged.value ? { publisher: publisherRef.value } : {}),
    ...(publishedHasChanged.value
      ? {
          published:
            publishedRef.value &&
            utc(publishedRef.value, 'YYYY-MM-DD').toDate(),
        }
      : {}),
    ...(accessibilityHasChanged.value
      ? {
          accessibility: {
            ...(accessibilityConformsToListHasChanged.value
              ? {
                  conformsTo: accessibilityConformsToRef.value,
                }
              : {}),
            ...(accessibilityAccessModesListHasChanged.value
              ? {
                  accessModes: accessibilityAccessModesRef.value,
                }
              : {}),
            ...(accessibilitySummaryHasChanged.value
              ? {
                  summary: accessibilitySummaryRef.value,
                }
              : {}),
            ...(accessibilityAccessModesSufficientsListHasChanged.value
              ? {
                  accessModesSufficient:
                    accessibilityAccessModesSufficientsRef.value,
                }
              : {}),
            ...(accessibilityHazardsListHasChanged.value
              ? {
                  hazards: accessibilityHazardsRef.value,
                }
              : {}),
            ...(accessibilityFeaturesListHasChanged.value
              ? {
                  features: accessibilityFeaturesRef.value,
                }
              : {}),

            ...(accessibilityCertificationHasChanged.value
              ? {
                  certification: {
                    ...(accessibilityCertByHasChanged.value
                      ? {
                          certifiedBy: accessibilityCertByRef.value,
                        }
                      : {}),
                    ...(accessibilityCertReportsListHasChanged.value
                      ? {
                          reports: accessibilityCertReportsRef.value,
                        }
                      : {}),
                    ...(accessibilityCertCredentialHasChanged.value
                      ? {
                          credential: accessibilityCertCredentialRef.value,
                        }
                      : {}),
                  },
                }
              : {}),
          },
        }
      : {}),
    ...(seriesHasChanged.value
      ? {
          isPartOf: {
            ...(seriesIssnHasChanged.value
              ? { issn: seriesIssnRef.value }
              : {}),
            ...(seriesNameHasChanged.value
              ? { name: seriesNameRef.value }
              : {}),
            ...(seriesTypeHasChanged.value
              ? { type: seriesTypeRef.value }
              : {}),
            ...(seriesIssueNumberHasChanged.value
              ? { issueNumber: seriesIssueNumberRef.value }
              : {}),
            ...(seriesPositionHasChanged.value
              ? { position: seriesPositionRef.value }
              : {}),
          },
        }
      : {}),
    ...(contributorsHasChanged.value
      ? {
          contributors: contributorsRef.value?.map((p) => ({
            role: p.label as Role,
            person: { name: p.value },
          })),
        }
      : {}),
    ...(subjectsHasChanged.value
      ? {
          subjects: subjectsRef.value?.map((s) => ({
            scheme: s.label,
            code: s.value,
          })),
        }
      : {}),
    ...(collectionsHasChanged.value
      ? {
          collections: collectionsRef.value?.map((s) => ({
            label: s,
          })),
        }
      : {}),
    ...(audiencesHasChanged.value
      ? { audiences: audiencesRef.value?.map((a) => ({ code: a as Audience })) }
      : {}),
    ...(ageRangeAudiencesHasChanged.value
      ? { ageRangeAudiences: ageRangeAudiencesRef.value }
      : {}),
  },
}));

const {
  mutate: saveChanges,
  loading: saveChangesLoading,
  error: saveChangesError,
  called: saveCalled,
  onDone: onSaveDone,
  onError: onSaveError,
} = useMutation(
  graphql(`
    mutation updateMetadata(
      $id: ID!
      $changes: UpdatePublicationMetadataChanges!
    ) {
      updatePublicationMetadata(id: $id, changes: $changes) {
        success
        publication {
          ...PublicationEditMetadata
        }
      }
    }
  `),
  () => ({ variables: variables.value })
);

onSaveDone((result) => {
  if (result.data?.updatePublicationMetadata.success) {
    router.push({
      name: 'publication',
      params: { id: publicationId.value },
    });
  }
});

onSaveError((result) => {
  if (result.graphQLErrors[0]?.extensions?.code != 'BAD_USER_INPUT') {
    throw new Error(result.message);
  }
});

const noInputErrors = computed(
  () =>
    langErrors.value.length === 0 &&
    ageRangeValidation({
      from: ageRangeFromRef.value,
      to: ageRangeToRef.value,
    }).length === 0
);

const changesExist = computed<boolean>(
  () =>
    titleHasChanged.value ||
    subtitleHasChanged.value ||
    pageCountHasChanged.value ||
    descriptionHasChanged.value ||
    natureHasChanged.value ||
    summaryHasChanged.value ||
    hasAdultConsiderationHasChanged.value ||
    imprintHasChanged.value ||
    paperIsbnHasChanged.value ||
    langHasChanged.value ||
    publisherHasChanged.value ||
    publishedHasChanged.value ||
    contributorsHasChanged.value ||
    subjectsHasChanged.value ||
    seriesIssnHasChanged.value ||
    seriesTypeHasChanged.value ||
    seriesNameHasChanged.value ||
    seriesPositionHasChanged.value ||
    seriesIssueNumberHasChanged.value ||
    collectionsHasChanged.value ||
    audiencesHasChanged.value ||
    ageRangeAudiencesHasChanged.value ||
    accessibilityHasChanged.value
);

const descriptionFormInfo: string =
  'Enter a detailed description, summary, abstract, or excerpt to provide readers with a comprehensive understanding of the publication. The description field allows you to share additional details beyond the summary and metadata, offering a chance to provide richer information about the publication.';
const summaryFormInfo: string =
  'Enter a concise summary, abstract, or excerpt that captures the essence of the publication. The summary offers readers an overview into the content and serves as a brief introduction to quickly assess its relevance and appeal.';

// Extracted metadata
// for now only accessbility is used
const mergeExtractedMetadataDone = ref(false);
const copyMetadataBeforeMerge: Ref<PublicationMetadata['accessibility']> =
  ref(null);

const handleExtractedMetadata = () =>
  mergeExtractedMetadataDone.value ? revertMerge() : doMerge();

const revertMerge = () => {
  mergeMetadata(copyMetadataBeforeMerge.value);
  copyMetadataBeforeMerge.value = null;
  mergeExtractedMetadataDone.value = false;
};

const doMerge = () => {
  if (!result.value?.publication?.extractedMetadata?.accessibility) {
    return;
  }
  copyMetadataBeforeMerge.value = {
    accessModes: accessibilityAccessModesRef.value!,
    accessModesSufficient: accessibilityAccessModesSufficientsRef.value!,
    conformsTo: accessibilityConformsToRef.value!,
    features: accessibilityFeaturesRef.value!,
    hazards: accessibilityHazardsRef.value!,
    summary: accessibilitySummaryRef.value!,
    certification: {
      certifiedBy: accessibilityCertByRef.value!,
      reports: accessibilityCertReportsRef.value!,
      credential: accessibilityCertCredentialRef.value!,
    },
  };

  mergeMetadata(
    result.value?.publication.extractedMetadata?.accessibility ?? null
  );
  mergeExtractedMetadataDone.value = true;
};

const mergeMetadata = (
  metadata: PublicationMetadata['accessibility'] | null
) => {
  if (metadata == null) {
    return;
  }

  accessibilityAccessModesRef.value = metadata.accessModes;
  accessibilityAccessModesSufficientsRef.value = metadata.accessModesSufficient;
  accessibilityCertByRef.value = metadata.certification?.certifiedBy;
  accessibilityCertCredentialRef.value = metadata.certification?.credential;
  accessibilityCertReportsRef.value = metadata.certification?.reports;
  accessibilityConformsToRef.value = metadata.conformsTo;
  accessibilityFeaturesRef.value = metadata.features;
  accessibilityHazardsRef.value = metadata.hazards;
  accessibilitySummaryRef.value = metadata.summary ?? undefined;
};

const formValues = computed(() => ({
  accessModes: accessibilityAccessModesRef.value ?? [],
  accessModesSufficient: accessibilityAccessModesSufficientsRef.value ?? [],
  conformsTo: accessibilityConformsToRef.value ?? [],
  features: accessibilityFeaturesRef.value ?? [],
  hazards: accessibilityHazardsRef.value ?? [],
  summary: accessibilitySummaryRef.value ?? null,
  certification: {
    certifiedBy: accessibilityCertByRef.value ?? null,
    reports: accessibilityCertReportsRef.value ?? [],
    credential: accessibilityCertCredentialRef.value ?? null,
  },
}));

const mergingExtractedMetadataChangesNothing = computed(() => {
  const extractedMetadata =
    result.value?.publication?.extractedMetadata?.accessibility;

  const accessModesIsEqual = isEqual(
    extractedMetadata?.accessModes,
    formValues.value.accessModes
  );

  const accessModesSufficientIsEqual = isEqual(
    extractedMetadata?.accessModesSufficient,
    formValues.value.accessModesSufficient
  );

  const conformsToIsEqual = isEqual(
    extractedMetadata?.conformsTo,
    formValues.value.conformsTo
  );

  const featuresIsEqual = isEqual(
    extractedMetadata?.features,
    formValues.value.features
  );

  const hazardsIsEqual = isEqual(
    extractedMetadata?.hazards,
    formValues.value.hazards
  );

  const summaryIsEqual = isEqual(
    extractedMetadata?.summary ?? null,
    formValues.value.summary ?? null
  );

  const certifiedByIsEqual = isEqual(
    extractedMetadata?.certification?.certifiedBy ?? null,
    formValues.value.certification.certifiedBy ?? null
  );

  const credentialIsEqual = isEqual(
    extractedMetadata?.certification?.credential ?? null,
    formValues.value.certification.credential ?? null
  );

  const reportsIsEqual = isEqual(
    extractedMetadata?.certification?.reports ?? [],
    formValues.value.certification.reports ?? []
  );

  return (
    accessModesIsEqual &&
    accessModesSufficientIsEqual &&
    conformsToIsEqual &&
    featuresIsEqual &&
    hazardsIsEqual &&
    summaryIsEqual &&
    certifiedByIsEqual &&
    credentialIsEqual &&
    reportsIsEqual
  );
});
</script>

<style scoped>
.form-input {
  @apply w-full block border border-gray-200 rounded px-3 py-2 leading-6 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50;
}

.form-input-changed {
  @apply focus:border-purple-500 focus:ring-purple-500 border-purple-500;
}

.form-input-checkbox {
  @apply block border border-gray-200 rounded px-3 py-2 leading-6 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50;
}

.list {
  @apply border border-gray-200 rounded bg-white divide-y divide-gray-200;
}

.list-changed {
  @apply border-purple-500  divide-purple-500;
}

.right-col-block {
  @apply flex flex-col rounded shadow-sm bg-white overflow-hidden md:w-2/3;
}
</style>
