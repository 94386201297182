<template>
  <message-confirmation
    :isHidden="hideExpireLicenseAlert"
    bodyInfoMessage="Are you sure you want to expire the license?"
    variant="alert"
    @answerUser="answerExpireLicenseAlert"
    button-cancel-message="Cancel"
    title-info-message="Expire License"
    :show-cancel-button="true"
  ></message-confirmation>
  <message-confirmation
    :isHidden="hideCancelLicenseAlert"
    bodyInfoMessage="Are you sure you want to cancel this license?"
    variant="alert"
    @answerUser="answerCancelLicenseAlert"
    button-cancel-message="Cancel"
    button-confirm-message="Cancel Licence"
    title-info-message="Cancel Licence"
    :show-cancel-button="true"
  ></message-confirmation>
  <message-confirmation
    :isHidden="hideCreateLoanAlert"
    :bodyInfoMessage="loanAlertMessage"
    variant="alert"
    @answerUser="answerCreateLoanAlert"
    button-cancel-message="Cancel"
    button-confirm-message="Create Loan"
    title-info-message="Create Loan"
    :show-cancel-button="true"
  ></message-confirmation>
  <message-confirmation
    :isHidden="hideReturnLoanAlert"
    bodyInfoMessage="Are you sure you want to return the loan?"
    variant="alert"
    @answerUser="answerReturnLoanAlert"
    :show-cancel-button="true"
    button-cancel-message="Cancel"
    button-confirm-message="Return Loan"
    title-info-message="Return Loan"
  ></message-confirmation>
  <template v-if="loading"> Loading...</template>
  <template v-else-if="!license">
    <error-display v-if="error" headerMessage="Error" :error="error" />
    <warning-display
      v-else
      headerMessage="Warning"
      warning="License Not Found"
    />
  </template>
  <template v-else-if="license">
    <h2 class="page-title">
      License <em>{{ formatLicenseId(license.id) }}</em>
    </h2>
    <show-licenses-publication
      :entityId="license.entityId"
      :target="license.target"
      :namespaceId="license.namespaceId"
      card-style="py-4 px-5 lg:px-6 w-full bg-gray-50"
      header-style="font-semibold text-2xl"
      title-style="text-gray-500 text-sm font-medium tracking-wider"
      target-style="text-gray-500 tracking-wider"
      loading-style="text-lg text-gray-500"
      error-style="text-lg text-gray-700"
      link-style="flex flex-row"
      cover-wrap-style="mr-4 h-[100px]"
      cover-style="h-full object-contain"
      load-cover
    />
    <div class="grid grid-cols-1 md:grid-cols-3 gap-4 lg:gap-8 mt-8">
      <div
        v-if="isOpenAccessLicense"
        class="flex flex-col rounded shadow-sm bg-white"
      >
        <div class="p-5 lg:p-6 flex-grow w-full">
          <dl>
            <dt class="text-2xl font-semibold">Open Access</dt>
            <dd
              class="uppercase font-medium text-sm text-gray-500 tracking-wider"
            >
              Restriction type
            </dd>
          </dl>
        </div>
      </div>

      <template v-if="!isOpenAccessLicense">
        <div class="flex flex-col rounded shadow-sm bg-white">
          <div class="p-5 lg:p-6 flex-grow w-full">
            <dl>
              <dt class="text-2xl font-semibold">
                {{
                  maybeInfiniteRatio(
                    license.checkouts.left,
                    license.terms?.checkouts
                  )
                }}
              </dt>
              <dd
                class="uppercase font-medium text-sm text-gray-500 tracking-wider"
              >
                Usages left
              </dd>
            </dl>
          </div>
        </div>

        <div class="flex flex-col rounded shadow-sm bg-white">
          <div class="p-5 lg:p-6 flex-grow w-full">
            <dl>
              <dt class="text-2xl font-semibold">
                {{
                  maybeInfiniteRatio(
                    license.checkouts.available,
                    license.terms?.concurrency
                  )
                }}
              </dt>
              <dd
                class="uppercase font-medium text-sm text-gray-500 tracking-wider"
              >
                Remaining Concurrency
              </dd>
            </dl>
          </div>
        </div>

        <div class="flex flex-col rounded shadow-sm bg-white">
          <div class="p-5 lg:p-6 flex-grow w-full">
            <dl>
              <template v-if="license.terms?.length">
                <dt class="text-xl font-semibold uppercase">
                  <div v-html="loanDuration(license.terms.length)"></div>
                </dt>
              </template>
              <template v-else>
                <dt class="text-2xl font-semibold">Unbound</dt>
              </template>
              <dd
                class="uppercase font-medium text-sm text-gray-500 tracking-wider"
              >
                Loan Duration
              </dd>
            </dl>
          </div>
        </div>

        <div
          class="flex flex-col rounded shadow-sm bg-white"
          v-if="license.terms?.expires"
        >
          <div class="p-5 lg:p-6 flex-grow w-full">
            <dl>
              <dt class="text-2xl font-semibold">
                {{
                  license.terms.capLoanExpirationToLicense
                    ? 'Caps loans expiration to license expiration.'
                    : 'Loans expirations can outlast license expiration.'
                }}
              </dt>
            </dl>
          </div>
        </div>

        <div class="flex flex-col rounded shadow-sm bg-white">
          <div class="p-5 lg:p-6 flex-grow w-full">
            <dl>
              <dt class="text-2xl font-semibold">
                <dm-date
                  v-if="license.terms?.expires"
                  :date="license.terms.expires"
                  clickable
                />
                <span v-else>Never</span>
              </dt>
              <dd
                v-if="licenseExpired"
                class="uppercase font-medium text-sm text-gray-500 tracking-wider"
              >
                Expired
              </dd>
              <dd
                v-else
                class="uppercase font-medium text-sm text-gray-500 tracking-wider"
              >
                Expiration
              </dd>
            </dl>
          </div>
        </div>
      </template>

      <div class="flex flex-col rounded shadow-sm bg-white">
        <div class="p-5 lg:p-6 flex-grow w-full">
          <dl>
            <dt class="text-2xl font-semibold">
              {{ license.status }}
            </dt>
            <dd
              class="uppercase font-medium text-sm text-gray-500 tracking-wider"
            >
              STATUS
            </dd>
          </dl>
        </div>
      </div>

      <div class="flex flex-col rounded shadow-sm bg-white">
        <div class="p-5 lg:p-6 flex-grow w-full">
          <dl>
            <dt class="text-2xl font-semibold">
              <dm-date :date="license.created" clickable />
            </dt>
            <dd
              class="uppercase font-medium text-sm text-gray-500 tracking-wider"
            >
              Creation Date
            </dd>
          </dl>
        </div>
      </div>
      <div class="flex flex-col rounded shadow-sm bg-white">
        <div class="p-5 lg:p-6 flex-grow w-full">
          <dl>
            <dt class="text-2xl font-semibold">
              <dm-date
                v-if="license.terms?.expires"
                :date="license.terms.expires"
                clickable
              />
              <span v-else>Never</span>
            </dt>
            <dd
              v-if="licenseExpired"
              class="uppercase font-medium text-sm text-gray-500 tracking-wider"
            >
              Expired
            </dd>
            <dd
              v-else
              class="uppercase font-medium text-sm text-gray-500 tracking-wider"
            >
              Expiration
            </dd>
          </dl>
        </div>
      </div>
    </div>

    <template v-if="license.customAttributes.length > 0">
      <h2
        class="text-2xl font-bold py-2 pt-8 border-b-2 border-gray-200 mb-4 lg:mb-8"
      >
        Custom Attributes
      </h2>
      <Table center-header :headers="['Key', 'Value', 'Actions']">
        <tr
          v-for="customAttribute of license.customAttributes"
          v-bind:key="customAttribute.key"
          class="border-b border-gray-200 text-center"
        >
          <td class="p-3">
            {{ customAttribute.key }}
          </td>

          <td class="p-3">
            {{ customAttribute.value }}
          </td>
          <td class="p-3 text-center"></td>
        </tr>
      </Table>
    </template>

    <template v-if="hasLicensesWriteRight">
      <h2
        class="text-2xl font-bold py-2 pt-8 border-b-2 border-gray-200 mb-4 lg:mb-8"
      >
        Actions
      </h2>
      <div>
        <!-- Button (small orange) -->
        <dm-button
          variant="danger"
          :enabled="!licenseExpired"
          :loading="forceLicenseSyncLoading"
          @click="forceLicenseSync()"
        >
          Force License Sync
        </dm-button>
        <dm-button
          variant="danger"
          class="ml-4"
          :enabled="!licenseExpired"
          :loading="forceLicenseExpirationLoading"
          @click="showExpireLicenseAlert()"
        >
          Force License Expire
        </dm-button>
        <dm-button
          variant="danger"
          class="ml-4"
          :loading="cancelLicenseLoading"
          :enabled="license.status != 'CANCELLED'"
          @click="showCancelLicenseAlert()"
        >
          Cancel Licence
        </dm-button>

        <dm-button
          variant="danger"
          class="ml-4"
          :loading="createLoanLoading"
          :enabled="!licenseExpired && license.status != 'CANCELLED'"
          @click="triggerCreateLoan()"
        >
          Create Loan
        </dm-button>
      </div>
    </template>

    <div v-if="createLoanError">
      <div
        class="border border-gray-200 rounded overflow-x-auto min-w-full bg-red-100 mt-4"
      >
        <p class="py-3 pl-3">
          An error has occurred while creating a loan, please contact your
          system administrator.
        </p>
      </div>
    </div>
    <div v-if="forceLicenseExpirationError">
      <div
        class="border border-gray-200 rounded overflow-x-auto min-w-full bg-red-100 mt-4"
      >
        <p class="py-3 pl-3">
          An error has occurred while expiring the license, please contact your
          system administrator(
          {{ forceLicenseExpirationError.message.split(':')[1] }} )
        </p>
      </div>
    </div>
    <div v-if="cancelLicenseError">
      <div
        class="border border-gray-200 rounded overflow-x-auto min-w-full bg-red-100 mt-4"
      >
        <p class="py-3 pl-3">
          An error has occurred while cancelling the license, please contact
          your system administrator(
          {{ cancelLicenseError.message.split(':')[1] }} )
        </p>
      </div>
    </div>
    <loan-list
      :loanList="license.checkouts.activeLoans"
      :loading="loading"
      :title="'Active Loans'"
      :status="true"
      :pageInfo="license.checkouts.activeLoans.pageInfo"
      :enableActions="hasLicensesWriteRight"
      @next-page="activeNextPage($event)"
      @previous-page="activePreviousPage($event)"
      @return-loan="triggerReturnLoan($event)"
    >
    </loan-list>

    <loan-list
      :loanList="license.checkouts.pastLoans"
      :loading="loading"
      :pageInfo="license.checkouts.pastLoans.pageInfo"
      :title="'Past Loans'"
      :status="false"
      @next-page="pastNextPage($event)"
      @previous-page="pastPreviousPage($event)"
    ></loan-list>
  </template>
</template>

<script setup lang="ts">
import { useQuery, useMutation } from '@vue/apollo-composable';
import dayjs from 'dayjs';
import { computed, inject, ref, Ref } from 'vue';
import { useRoute } from 'vue-router';
import Crypto from 'crypto-js';
import { useToast } from 'vue-toastification';
import ErrorDisplay from '../ErrorDisplay.vue';
import WarningDisplay from '../WarningDisplay.vue';

import { graphql } from '../../__generated__/gql';
import { GetLicenseQueryVariables } from '../../__generated__/graphql';

import ShowLicensesPublication from './publications/ShowLicensesPublication.vue';
import MessageConfirmation from '../base/DialogMessage.vue';
import LoanList from '@/components/loans/LoanList.vue';
import { useAuth } from '../../auth';
import LinkToast from '../base/LinkToast.vue';
import { GLOBAL_FETCH } from '../../main';
import { humanReadableDuration } from '../../utils';
import Table from '../base/Table.vue';

const globalFetch = inject(GLOBAL_FETCH)!;

const { getTokenSilently, canWriteResourceTypeOnAtLeastOneNamespace } =
  useAuth();

const hasLicensesWriteRight =
  canWriteResourceTypeOnAtLeastOneNamespace('licenses');

const { result: userResult, error: userError } = useQuery(
  graphql(`
    query CurrentUser {
      viewer {
        id
      }
    }
  `)
);

const toast = useToast();

const _LICENSE_FRAGMENT = graphql(`
  fragment ShowLicensePageLicense on License {
    id
    namespaceId
    entityId
    target
    created
    status

    terms {
      checkouts
      concurrency
      expires
      capLoanExpirationToLicense
    }

    checkouts {
      left
      available
      activeLoans {
        pageInfo {
          hasPreviousPage
          hasNextPage
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            id
            borrowerId
            transactionId
            expires
          }
        }
      }
      pastLoans {
        pageInfo {
          hasPreviousPage
          hasNextPage
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            id
            borrowerId
            transactionId
            expires
          }
        }
      }
    }

    customAttributes {
      key
      value
    }
  }
`);

const formatLicenseId = (id: string): string => {
  let splits = id.split('/');
  return splits[splits.length - 1];
};

const maybeInfiniteRatio = (
  num: number | null | undefined,
  denum: number | null | undefined
): string => {
  if (num === null || denum === null) {
    return 'Unlimited';
  }
  return `${num} / ${denum}`;
};

const loanDuration = (seconds: number) => {
  const secondstoDuration = humanReadableDuration(seconds);
  const duration = secondstoDuration
    .replace(/(\d+)d/g, '$1 <span class="duration">day(s)</span>')
    .replace(/(\d+)h/g, '$1 <span class="duration">hour(s)</span>')
    .replace(/(\d+)m/g, '$1 <span class="duration">minute(s)</span>');

  return duration;
};

const perPage = 10;
const route = useRoute();
const licenseId = computed(() =>
  Array.isArray(route.params.id) ? route.params.id[0] : route.params.id
);

const variables: Ref<GetLicenseQueryVariables> = computed(() => ({
  id: licenseId.value,
  pastFirst: perPage,
  pastLast: null,
  pastAfter: undefined,
  pastBefore: undefined,
  activeFirst: perPage,
  activeLast: null,
  activeAfter: undefined,
  activeBefore: undefined,
}));

const { result, loading, fetchMore, refetch, error } = useQuery(
  graphql(`
    query GetLicense(
      $id: ID!
      $activeFirst: Int
      $activeLast: Int
      $activeAfter: String
      $activeBefore: String
      $pastFirst: Int
      $pastLast: Int
      $pastAfter: String
      $pastBefore: String
    ) {
      license(id: $id) {
        id
        namespaceId
        entityId
        target
        created
        status
        publication {
          id
          isbn
          cover {
            href
          }
          metadata {
            title
          }
        }
        restrictionType
        terms {
          checkouts
          concurrency
          expires
          capLoanExpirationToLicense
          length
        }
        customAttributes {
          key
          value
        }
        checkouts {
          left
          available
          activeLoans(
            first: $activeFirst
            last: $activeLast
            after: $activeAfter
            before: $activeBefore
          ) {
            pageInfo {
              hasPreviousPage
              hasNextPage
              startCursor
              endCursor
            }
            edges {
              cursor
              node {
                id
                borrowerId
                transactionId
                expires
              }
            }
          }
          pastLoans(
            first: $pastFirst
            last: $pastLast
            after: $pastAfter
            before: $pastBefore
          ) {
            pageInfo {
              hasPreviousPage
              hasNextPage
              startCursor
              endCursor
            }
            edges {
              cursor
              node {
                id
                borrowerId
                transactionId
                expires
              }
            }
          }
        }
      }
    }
  `),
  variables
);

const license = computed(() => result.value?.license ?? null);

const isOpenAccessLicense = computed(
  () => license.value?.restrictionType === 'OPEN_ACCESS'
);

/*Check if license is expired */
const licenseExpired = computed(() =>
  dayjs(license.value?.terms?.expires) < dayjs() ? true : false
);

const {
  mutate: forceLicenseSync,
  loading: forceLicenseSyncLoading,
  error: forceLicenseSyncError,
  onDone: onForceLicenseSyncDone,
} = useMutation(
  graphql(`
    mutation ForceLicenseUpdate($licenseId: ID!) {
      forceLicenseUpdate(id: $licenseId)
    }
  `),
  () => ({
    variables: {
      licenseId: licenseId.value,
    },
  })
);

/**Expire license*/
const hideExpireLicenseAlert = ref(true);

/*Confirm performed action*/
const answerExpireLicenseAlert = async (answer: boolean) => {
  hideExpireLicenseAlert.value = true;
  if (answer) {
    forceLicenseExpiration();
  }
};

const {
  mutate: forceLicenseExpiration,
  loading: forceLicenseExpirationLoading,
  error: forceLicenseExpirationError,
  onDone: onForceLicenseExpirationDone,
} = useMutation(
  graphql(`
    mutation expireLicense($licenseId: ID!) {
      expireLicense(id: $licenseId) {
        changed
        license {
          ...ShowLicensePageLicense
        }
      }
    }
  `),
  () => ({
    variables: {
      licenseId: licenseId.value,
    },
  })
);

function showExpireLicenseAlert() {
  hideExpireLicenseAlert.value = false;
}

/* Cancel Licence */

const {
  mutate: cancelLicense,
  loading: cancelLicenseLoading,
  error: cancelLicenseError,
  onDone: onCancelLicenceDone,
} = useMutation(
  graphql(`
    mutation cancelLicense($licenseId: ID!) {
      cancelLicense(id: $licenseId) {
        license {
          ...ShowLicensePageLicense
        }
      }
    }
  `),
  () => ({
    variables: {
      licenseId: licenseId.value,
    },
  })
);

onCancelLicenceDone(() => {
  toast.success('Licence cancelled');
});

const hideCancelLicenseAlert = ref(true);

const showCancelLicenseAlert = () => {
  hideCancelLicenseAlert.value = false;
};

const answerCancelLicenseAlert = async (answer: boolean) => {
  hideCancelLicenseAlert.value = true;
  if (answer) {
    cancelLicense();
  }
};

/* Return Loan */
const hideReturnLoanAlert = ref(true);

const currentReturnLoanId = ref<string | null>(null);

const triggerReturnLoan = (loanId: string) => {
  currentReturnLoanId.value = loanId;

  hideReturnLoanAlert.value = false;
};

const answerReturnLoanAlert = async (answer: boolean) => {
  hideReturnLoanAlert.value = true;

  let returnLoanId = currentReturnLoanId.value;
  currentReturnLoanId.value = null;

  if (answer && returnLoanId) {
    returnLoan(returnLoanId);
  }
};

const {
  mutate: returnLoanMutation,
  loading: returnLoanLoading,
  error: returnLoanError,
  onDone: onReturnLoanDone,
} = useMutation(
  graphql(`
    mutation ReturnLoan($loanId: ID!) {
      endLoan(id: $loanId, reason: REVOKED) {
        changed
        loan {
          license {
            ...ShowLicensePageLicense
          }
        }
      }
    }
  `)
);

const returnLoan = (loanId: string) => {
  returnLoanMutation({
    loanId: loanId,
  });
  refetch();
};

const loanAlertMessage =
  'Are you sure you want to create a loan? This is a limited license. It will consume a usage of the license.';

const hideCreateLoanAlert = ref(true);

const triggerCreateLoan = () => {
  if (
    license.value?.restrictionType == 'LIMITED' ||
    license.value?.terms?.checkouts
  ) {
    hideCreateLoanAlert.value = false;
  } else {
    createLoan();
  }
};

const answerCreateLoanAlert = async (answer: boolean) => {
  hideCreateLoanAlert.value = true;
  if (answer) {
    createLoan();
  }
};

const TEST_LOAN_PASSPHRASE = 'test-loan';

const fulfillUrl = import.meta.env.VITE_FULFILLMENT_BASE_URL;
const checkoutUrl = `${fulfillUrl}/licenses/checkout`;

const createLoanLoading = ref(false);
const createLoanError = ref(false);

const createLoan = async () => {
  try {
    createLoanError.value = false;

    const url = createURLForLoanCreation();

    createLoanLoading.value = true;

    let accessToken: any;
    if (import.meta.env.VITE_API_TOKEN) {
      accessToken = import.meta.env.VITE_API_TOKEN;
    } else {
      accessToken = await getTokenSilently({});
    }

    const response = await globalFetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data = await response.json();

    if (response.status >= 500) {
      throw data.message;
    }

    if (response.status >= 400) {
      toast.error(data.title);
    } else {
      toast.success({
        component: LinkToast,
        props: {
          paragraph: 'The loan has been created successfully.',
          linkHref: `#/loans/${encodeURIComponent(data.id)}`,
          linkText: 'See the loan here.',
        },
      });
    }

    createLoanLoading.value = false;
    refetch();
  } catch (error) {
    createLoanError.value = true;
    toast.error('An unexpected error has occurred.');
    throw error;
  }
};

const createURLForLoanCreation = () => {
  const searchParams = new URLSearchParams();

  searchParams.append('id', license.value?.id!);

  searchParams.append(
    'checkout_id',
    Crypto.SHA256(dayjs() + license.value?.id!).toString()
  );

  searchParams.append(
    'expires',
    license.value?.terms?.expires ?? dayjs().add(1, 'day').toJSON()
  );

  if (userResult.value?.viewer.id) {
    searchParams.append('patron_id', userResult.value.viewer.id);
  }

  searchParams.append(
    'passphrase',
    Crypto.enc.Base64.stringify(Crypto.SHA256(TEST_LOAN_PASSPHRASE))
  );

  return checkoutUrl + '?' + searchParams.toString();
};

function pastNextPage(endCursor: string) {
  fetchMore({
    variables: {
      pastFirst: perPage,
      pastLast: null,
      pastAfter: endCursor,
    },
    updateQuery: (previousResult, { fetchMoreResult }) =>
      fetchMoreResult || previousResult,
  });
}

function pastPreviousPage(startCursor: string) {
  fetchMore({
    variables: {
      pastLast: perPage,
      pastFirst: null,
      pastBefore: startCursor,
    },
    updateQuery: (previousResult, { fetchMoreResult }) =>
      fetchMoreResult || previousResult,
  });
}

function activeNextPage(endCursor: string) {
  fetchMore({
    variables: {
      activeFirst: perPage,
      activeLast: null,
      activeAfter: endCursor,
    },
    updateQuery: (previousResult, { fetchMoreResult }) =>
      fetchMoreResult || previousResult,
  });
}

function activePreviousPage(startCursor: string) {
  fetchMore({
    variables: {
      activeLast: perPage,
      activeFirst: null,
      activeBefore: startCursor,
    },
    updateQuery: (previousResult, { fetchMoreResult }) =>
      fetchMoreResult || previousResult,
  });
}
</script>

<style>
.duration {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.05em;
}
</style>
